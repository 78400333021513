import {
  TextField,
  Button,
  firebase,
  theme,
  alert,
  ErrorMessages,
} from "@project/shared";
import Link from "next/link";
import { useFormik } from "formik";
import * as yup from "yup";
import React from "react";
// import { message, notification } from 'antd';
import RestrictedRoute from "../../withRestrictedRoute";
import { useTranslation } from "react-i18next";
import Head from "next/head";
import styled from "styled-components";

import Image from "next/image";

const Wrapper = styled.div`
  height: 100vh;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-top: 30px;
  background-color: ${theme.gray3};

  & form {
    flex: 1;
    max-width: 500px;
  }
`;
const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const StyledTextFiled = styled(TextField)`
  margin-bottom: 16px;
`;

const LinkWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  & > a {
    color: ${theme.gray9};
    ${theme.typography.Caption};
    border-bottom: 0.2px solid ${theme.gray7};
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 50px;
`;
interface LoginType {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("Email is required."))
      .email(t("Email format is invalid.")),
    password: yup.string().required(t("Password is required.")),
  });
  const handleLoginFormSubmit = async (credentials: LoginType) => {
    setLoading(true);
    try {
      await loginUser(credentials);
    } catch (error) {
      const errorMessage = ErrorMessages[error.code];
      if (errorMessage) {
        alert({ message: t(errorMessage), isSuccess: false });
      } else {
        alert({
          message: t("An error has occurred. Please try again later."),
          isSuccess: false,
        });
      }
    }
    setLoading(false);
  };

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  });

  return (
    <>
      <Head>
        <title>
          {t("Login")} | {process.env.NEXT_PUBLIC_PAGE_TITLE}
        </title>
      </Head>
      <Wrapper>
        <LogoWrapper>
          <Image
            src="/logo.png"
            width="200px"
            height="52px"
            quality={100}
            objectFit="cover"
          />
        </LogoWrapper>
        <form onSubmit={formik.handleSubmit}>
          <StyledTextFiled
            name="email"
            error={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("Email address")}
          />
          <StyledTextFiled
            name="password"
            type="password"
            error={formik.touched.password && formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("Password")}
          />
          <BottomWrapper>
            <Button loading={loading} htmlType="submit" type="primary">
              {t("Login")}
            </Button>
            <LinkWrapper>
              <Link href={`/forget-password`} as={`/forget-password`}>
                <a>{t("Forgot Password?")}</a>
              </Link>
            </LinkWrapper>
          </BottomWrapper>
        </form>
      </Wrapper>
    </>
  );
};

const loginUser = async (credendials: LoginType) => {
  await firebase
    .auth()
    .signInWithEmailAndPassword(credendials.email, credendials.password);
};

export default RestrictedRoute(LoginPage);
