import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "@project/shared/utils/AuthContext";
import Router from "next/router";
import { Spin } from "antd";

const RestrictedRoute = (AuthComponent) => {
  function RestrictedComponent({ children }) {
    const { authenticated, loading } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (authenticated == null) {
        return;
      }
      if (authenticated) {
        Router.push("/");
      }

      setIsLoading(false);
    }, [authenticated, loading]);
    if (loading || isLoading) {
      return (
        <div className="loader">
          <Spin size="large" className="loader" />
        </div>
      );
    }
    return <>{children}</>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <AuthComponent {...this.props} />
        </RestrictedComponent>
      );
    }
  };
};

export default RestrictedRoute;
